import React from 'react'
import classNames from 'classnames'
import { sanitizeString } from '../../utils/sanitizeString'
import { BioNoResults, BioBoxPosts } from '../shared'
import { BioButton } from '../shared'
import { PreviewCard } from '../shared'
import * as styles from './../../css/molecules/bioPostGrid.module.scss'

export const PostGrid = ({ items, noSearchResultsText }) => {
  if (items.length === 0) return <BioNoResults content={noSearchResultsText} />
  return (
    <div className={classNames(styles.bioPostGrid, 'newCards')}>
      {items.map((post, index) => {
        return (
          <PreviewCard
            key={index}
            path={`${post.basePath}/${sanitizeString(post.category)}/${
              post.slug.current || post.slug
            }`}
            // category={post.category}
            previewImage={post.previewImage}
            hasCategoryOnImage={false}>
            {post?.icon ? (
              <BioBoxPosts classes={styles.bioPostGrid__box}>
                <img
                  className={styles.bioPostGrid__icon}
                  src={post?.icon}
                  alt={post?.icon?.alt}
                />
              </BioBoxPosts>
            ) : null}
            <h4
              className={classNames(
                'bioCategory',
                styles.bioPostGrid__category
              )}>
              {post.category}
            </h4>
            <h3 className={styles.bioPostGrid__title}>{post.title}</h3>
            <p className={styles.__description}>{post.desc}</p>
            <div className={styles.__buttonContainer}>
              <BioButton
                style="isSmall isWide"
                button={{ buttonCta: 'Mehr erfahren' }}
              />
            </div>
          </PreviewCard>
        )
      })}
    </div>
  )
}
